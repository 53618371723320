.pdfFileWrapper {
    display: flex;
    gap: 8px;
    align-items: center;
}

.pdfFileInfo {
    display: flex;
    flex-direction: column;
    gap: 8px;
}
