.card-logout {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 40px 40px 40px 31px;
    width: 360px;
    min-height: 400px;
    border: 0 solid transparent;
    border-left: 9px solid transparent;
    background: linear-gradient(80.47deg, #18181F 6.62%, #2D2D3A 148.62%) padding-box, linear-gradient(25.41deg, #6E27F8 2.1%, #20D9C0 71.42%) border-box;
    box-shadow: -12px 13px 30px rgba(32, 217, 192, 0.15);
    border-radius: 16px;
    border-color: transparent;
    border-width: 0 0 0 9px;
    gap:40px
}

.card-logout-h5 {
    font-family: 'Inter',sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: #FBFBFF;
}

.card-logout-text {
    font-family: 'Inter',sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    color: #FBFBFF;
}

.card-logout-span {
    font-family: 'Inter',sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #FBFBFF;
}

.card-logout-button {
    font-family: 'Inter',sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    color: #FBFBFF;
    background: none;
    border: none;
    transition: 0.2s color;
    cursor: pointer;
}

.card-logout-button:hover {
    color: #7B59FD;
}
