.spot-table-link, .spot-table-link_mob {
    font-size: 14px;
    font-weight: 400;
    color: #fbfbff !important;
    transition: all 0.5s ease-out;

    &:hover {
        color: #5297FF !important;
    }

    &:active {
        color: #0055D3 !important;
    }
}
