.card-left-part {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 4px;
    flex: 1 0 0;
}

.card-right-part {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    gap: 4px;
    flex: 1 0 0;
}

.card-left-part-row {
    display: flex;
    align-items: center;
    gap: 4px;
}

.card-text-large {
    color: #FBFBFF;
    font-size: 14px;
    font-family: Inter, sans-serif;
}

.card-text-small {
    color: #FBFBFF;
    font-size: 12px;
    font-family: Inter, sans-serif;
    font-weight: 600;
}

