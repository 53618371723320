.ant-menu > .ant-menu-item {
    width: 100% !important;
    margin: 0 !important;
    padding-left: 13px !important;
    border-left: 3px solid transparent;
    background: transparent;
}

.ant-menu-light:not(.ant-menu-horizontal).ant-menu > .ant-menu-item:not(.ant-menu-item-selected):active {

}

:where(.css-dev-only-do-not-override-br05hh).ant-menu-light .ant-menu-submenu-selected >.ant-menu-submenu-title{
    padding-left: 15px !important;
}

.ant-menu-submenu-title{
    padding-left: 11px !important;
}

:where(.css-dev-only-do-not-override-1jo4mgj).ant-menu-light:not(.ant-menu-horizontal) .ant-menu-submenu-title:hover{
    //background: transparent;
}

.ant-menu-item:not(.ant-menu-item-selected):active{

}

.ant-menu-inline .ant-menu-submenu-title{
    margin-inline: unset !important;
    width: 100% !important;
}



.ant-menu > .ant-menu-item-selected,
.ant-menu > .ant-menu-submenu-selected {
    border-left: 3px solid transparent;
    background: linear-gradient(0deg, #14141F, #14141F) padding-box,  linear-gradient(75.86deg, #6320E6 6.84%, #7B59FD 99.53%) border-box;
}
