.exchange-order-container {
    margin-top: 22px;
    display: flex;
    flex-direction: column;
    gap: 32px;
    max-width: 1377px;
    margin-left: auto;
    margin-right: auto;
}

.exchange-order-main-container {
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.exchange-order-custom-step {
    display: flex;
    font-size: 32px;
    align-items: flex-end;
}

.exchange-order-main-content-card-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 40px;
}

.exchange-order-main-content-card-time {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 32px 32px 72px;
    gap: 48px;
    min-width: 288px;
    height: 440px;
    background: #FFFFFF;
    box-shadow: 0 0 1px rgba(12, 26, 75, 0.24), 0 3px 8px -1px rgba(50, 50, 71, 0.05);
    box-sizing: border-box;
}

.exchange-order-main-content-card-order-data {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 32px;
    gap: 40px;
    min-width: 674px;
    width: 100%;
    height: 440px;
    /* Grey colors/Grey 000 */
    box-shadow: 0 0 1px rgba(12, 26, 75, 0.24), 0 3px 8px -1px rgba(50, 50, 71, 0.05);
    box-sizing: border-box;
}

.exchange-order-main-content-card-transfer-address {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 32px 40px;
    gap: 8px;
    min-width: 343px;
    height: 440px;
    /* Grey colors/Grey 000 */
    background: #FFFFFF;
    box-shadow: 0 0 1px rgba(12, 26, 75, 0.24), 0 3px 8px -1px rgba(50, 50, 71, 0.05);
    box-sizing: border-box;
}

.upper-case {
    text-transform: uppercase;
}

.flex-column {
    display: flex;
    flex-direction: column;
}

.gap-4 {
    gap: 4px;
}

.bold-32px-text {
    font-weight: 700;
    font-size: 32px;
    line-height: 120%;

    color: #202124;
}

.text-32px {
    font-size: 32px;
    line-height: 120%;
}

.flex-row {
    display: flex;
    flex-direction: row;
}

.gap-16 {
    gap: 16px;
}

.gap-8 {
    gap: 8px;
}

.gap-10 {
    gap: 8px;
}

.marginbottom-12 {
    marginBottom: 12px;
}

.marginbottom-16 {
    marginBottom: 16px;
}

.middle-bold-14px-text {
    font-weight: 500;
    font-size: 14px;
    line-height: 14.52px;
}

.bold-14px-text {
    font-weight: 700;
    font-size: 14px;
    line-height: 120%;
}

.pre-bold-12px-text {
    font-weight: 600;
    font-size: 12px;
    line-height: 14.52px;
}

.bold-12px-text {
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
}

.light-bold-12px-text {
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
}

.light-grey-12px-text {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #C3C3CE;
}

.noselect {
    -moz-user-select: none;
    -webkit-user-select: none;
    -webkit-touch-callout: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
    -khtml-user-select: none;
}

.gray {
    color: #767687;
}

.bold-16px-text {
    font-weight: 700;
    font-size: 16px;
    line-height: 120%;
}

.text-16px-lineHeight-150 {
    font-size: 16px;
    line-height: 150%;
}

.text-16px {
    font-size: 16px;
    line-height: 120%;
}

.align-items-center {
    align-items: center;
}

.width-100-percent {
    width: 100%;
}

.justify-content-space-around {
    justify-content: space-around;
}

.gray-12px-text {
    font-weight: 500;
    font-size: 12px;
    line-height: 120%;
    text-align: center;
    color: #6B6E75;
}

.black-12px-text {
    font-weight: 400;
    font-size: 12px;
    line-height: 120%;
}

.text-align-center {
    text-align: center;
}

.title-card {
    font-weight: 400;
    font-size: 20px;
    line-height: 120%;

    color: #202124;
}

.gap-56 {
    gap: 56px;
}

.gap-45 {
    gap: 45px;
}

.gap-32 {
    gap: 32px;
}

.bold-18px-text {
    font-weight: 700;
    font-size: 18px;
    line-height: 120%;
}

.currency-icon-black {
    box-sizing: border-box;

    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px;
    width: 48px;
    height: 48px;
    /* Primary gradient/Dark */
    background: linear-gradient(75.86deg, #202124 6.84%, #404348 99.53%);
    box-shadow: 0 0 1px rgba(12, 26, 75, 0.24), 0 3px 8px -1px rgba(50, 50, 71, 0.05);
    border-radius: 16px;

}

.currency-icon-gradient {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px;
    width: 48px;
    height: 48px;
    /* Primary gradient/Red */
    background: radial-gradient(106.63% 327.54% at 4.27% 100%, #E272B6 0%, #CE80EF 51.04%, #7EABFF 92.44%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
    box-shadow: 0 0 1px rgba(12, 26, 75, 0.24), 0 3px 8px -1px rgba(50, 50, 71, 0.05);
    border-radius: 16px;
}

.gap-41 {
    gap: 41px;
}

.gap-18 {
    gap: 18px;
}

.round-list-type {
    position: relative;
    padding-left: 15px;
}

.round-list-type::before {
    border-radius: 100%;
    position: absolute;
    vertical-align: middle;
    width: 8px;
    height: 8px;
    background: linear-gradient(75.86deg, #6320E6 6.84%, #7B59FD 99.53%);
    line-height: 120%;
    top: 7px;
    left: 0;
    content: ' '
}

.height-100-percent {
    height: 100%;
}

.gap-90 {
    gap: 90px;
}

.justify-content-space-between {
    justify-content: space-between;
}

.justify-content-center {
    justify-content: center;
}

.justify-content-flex-end {
    justify-content: flex-end;
}

.gap-24 {
    gap: 24px
}

.gap-36 {
    gap: 36px
}

.gradient-background {
    background: radial-gradient(106.63% 327.54% at 4.27% 100%, #E272B6 0%, #CE80EF 51.04%, #7EABFF 92.44%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
}

.border-radius-8 {
    border-radius: 8px;
}

.small-white-text {
    font-size: 12px;
    line-height: 120%;
    color: white;
}

.cursor-pointer {
    cursor: pointer;
}

.ul-exchange {
    list-style-type: none;
}

.align-items-baseline {
    align-items: baseline;
}

.exchange-cards-wrapper {
    display: flex;
    gap: 32px;
    margin-bottom: 35px;
}
