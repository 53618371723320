
.p2p-ad-payment-modal-buy-payment-card {
    display: flex;
    align-items: center;
    padding: 8px 16px;
    margin-bottom: 4px;
    gap: 8px;
    border-radius: 4px;
    background-color: transparent;
    cursor: pointer;
    box-sizing: border-box;
}

.p2p-ad-payment-modal-buy-payment-card:hover {
    background-color: #2B2B36;
}

.p2p-ad-payment-modal-buy-payment-card:active {
    background-color: #2B2B36;
}

.p2p-ad-payment-modal-buy-payment-card.selected-item {
    background: linear-gradient(0deg,#0E0E13, #0E0E13) padding-box, linear-gradient(0deg, #6E27F8 2.1%, #20D9C0 71.42%) border-box;
    border-left: 3px solid transparent;
    padding-left: 13px;
}

.p2p-ad-payment-modal-buy-payment-card.disabled-item {
    background-color: #2B2B36;
    color: #474755;
    cursor: not-allowed;
}

.modal-payment-line, .modal-payment-line-end {
    border-bottom: 1px solid rgba(65, 61, 80, 0.50);
    margin: 16px 0;
}

.modal-payment-line-end {
    margin-bottom: 0;
}

.p2p-create-ad-empty-payment-types-wrapper {
    display: flex;
    gap: 32px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 480px;
}
