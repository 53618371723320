.tabs-margin-0 .ant-tabs-nav {
    margin: 0;
}

.tabs-margin-0 .ant-tabs-nav-wrap {
    border-bottom: 1px solid #474755;
}

.tabs-margin-0 .ant-tabs-nav-list > div > div {
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
}

.card {
    display: flex;
    flex-direction: column;
    padding: 24px 16px;
    gap: 24px;
    max-width: 100%;
    width: 100%;
    height: 100%;
    justify-content: space-between;
    border: 1px solid #474755;
    border-radius: 8px;
    background-color: #1c1c27;
}

/*.card:hover {*/
/*  background: #3f3f4b;*/
/*  border: 1px solid #474755;*/
/*  border-radius: 8px;*/
/*}*/

/*.card:active {*/
/*  background: #181827;*/
/*  border: 1px solid #474755;*/
/*  border-radius: 8px;*/
/*}*/

/**/
/**/
/**/

.flex {
    display: flex;
}

.flex-column {
    display: flex;
    flex-direction: column;
    /*justify-content: space-between;*/
}

.flex-row {
    display: flex;
    flex-direction: row;
}

.text-white {
    color: #fbfbff;
}

.text-gray {
    color: #767687;
}

.text-bold {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
}

.text-small {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
}

.gap-8 {
    gap: 8px;
}

.gap-25 {
    gap: 25px;
}

.gap-100 {
    gap: 100px;
}

.w-20 {
    width: 20%;
}

.w-100 {
    width: 100%;
}
.width-200 {
    width: 200px;
}
.width-150 {
    width: 150px;
}
.width-100 {
    width: 100px;
}

.align-items-center {
    align-items: center;
}

.jc-flex-start {
    justify-content: flex-start;
}

/* SELECT COIN */

.ant-select-selector
> .ant-select-selection-item
> div
> div
> .deals-select-desc {
    display: none !important;
}

/*BUTTON*/
.button-deals {
    all: unset;
    width: 32px;
    height: 32px;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.button-deals:hover {
    background-color: #474755;
}

.button-deals:active {
    background-color: #18181f;
}
.button-deals > button:disabled {
    all: unset;
    width: 32px;
    height: 32px;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent !important;
}
.button-deals > button:disabled > svg > g > path {
    fill: #474755;
}

.dealIconStatus svg:hover path {
    fill: #FBFBFF;
}

.dealIconStatus svg:active path {
    fill: #767687;
}

.button-deals:hover:has(button) {
  background-color: transparent !important;
}
.my-deals-dd {
    width: 260px !important;
}

.my-deals-dd > .rc-virtual-list > .rc-virtual-list-holder > div > .rc-virtual-list-holder-inner > .ant-select-item {
    padding: 8px 16px !important;
    height: 56px !important;
}

.my-ann-dd {
    width: 260px !important;
}

.my-ann-dd > .rc-virtual-list > .rc-virtual-list-holder > div > .rc-virtual-list-holder-inner > .ant-select-item {
    padding: 8px 16px !important;
    height: 56px !important;
}
