.create-ad-button{
    height: 40px;
    background: linear-gradient(270.4deg, #6E27F8 -85.22%, #20D9C0 164.99%);
    border-radius: 8px;
    padding: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 20px;
    right: 0;
    color: #FBFBFF!important;
    font-size: 14px;
    line-height: 17px;
}
.create-ad-button:hover{
    background: linear-gradient(270.4deg, #6E27F8 -85.22%, #20D9C0 164.99%)!important;
    color: #FBFBFF!important;
}