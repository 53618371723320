.message_badge_wrapper {
    /*padding: 16px 0;*/
    display: flex;
    gap: 20px;
    flex-direction: column;
    align-items: center;
    scroll-behavior: smooth;
}

.message_badge {
    background: #2B2B36;

    padding: 4px 12px;
    border-radius: 5px;
}
.message_badge__content {
    color: #fff;
    font-size: 12px;
}

