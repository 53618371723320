.ant-popover .ant-popover-inner {
    background-color: #2B2B36 !important;
}

.ant-popover .ant-popover-arrow::before {
    background-color: #2B2B36 !important;
}

.ant-popconfirm-message-title {
    font-size: 14px !important;
    font-family: Inter, sans-serif;
    color: #FBFBFF !important;
    margin-bottom: 16px;
    flex-wrap: wrap;
}

.ant-popconfirm-buttons {
    display: flex !important;
    justify-content: flex-end;
}

.ant-popover-inner {
    max-width: 288px;
    padding: 16px;
    border-radius: 8px;
    border: 1px solid #767687;
    background: #2B2B36;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
}

.ant-popconfirm-message-icon {
    margin-top: 2px;
}
