.container {
    display: flex;
    align-items: center;
    gap: 15px;
}

.container__titles {
    display: flex;
    flex-direction: column;
}

.title__name {
    /*text-transform: uppercase;*/
}

.title__info {
    color: #C3C3CE;
}
