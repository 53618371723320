
.p2p-ad-payment-type-modal-content-wrapper{
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items:center;
    width: 100%;
    max-height: 434px;
    overflow-y: auto;
    box-sizing: border-box;
}

.p2p-create-ad-choose-payment-type-item {
    display: flex;
    padding: 8px 16px;
    gap: 8px;
    border-radius: 4px;
    background-color: #2B2B36;
    width: 100%;
    cursor: pointer;
    box-sizing: border-box;
}

.p2p-create-ad-choose-payment-type-item:hover {
    background-color: #474755;
}


.p2p-create-ad-choose-payment-type-item:active {
    background-color: #18181F;
}

.p2p-create-ad-choose-payment-type-item.disabled-item {
    background-color: #2B2B36;
    cursor: not-allowed;
}

.p2p-create-ad-choose-payment-type-item.selected-item {
    background-color: #0E0E13;
    border-image: linear-gradient(rgba(32, 217, 192, 1), rgba(110, 39, 248, 1)) 3;
    border-left: 3px solid;
    padding-left: 13px;
}

.p2p-create-ad-choose-payment-type-item .right-part{
    display: flex;
    flex-direction: column;
    gap: 4px;
    justify-content: center;
}

.p2p-create-ad-choose-payment-type-item .icon-container{
    width: 32px;
    height: 32px;
}


.p2p-create-ad-empty-payment-types-wrapper {
    display: flex;
    gap: 32px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 480px;
}
