.files_container {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.files_container__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.files_container__description {
    font-size: 12px;
    color: #767687;

    width: 70%;
}

.files_container__max {
    color: #767687;
}

.files_upload .ant-upload-list-item-actions {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
}