
.bank-container::-webkit-scrollbar {
    background: none;
    width: 4px;
}

.bank-container::-webkit-scrollbar-thumb {
    background: #F3F3F8;
    border-radius: 4px;
}

.bank-title {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #FBFBFF;
}

.bank-form {
    margin-top: 32px;
    margin-bottom: 129px;
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.phone-form {
    margin-top: 32px;
    margin-bottom: 218px;
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.bank-container-input {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.bank-container-input-label-error {
    font-family: 'Inter',sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #F16063;
}

.bank-textarea {
    padding: 16px 12px;
    border-radius: 8px;
    border: 1px solid #474755;
    background: none;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #FBFBFF;
    height: 100px;
}

.bank-input {
    border-radius: 8px;
    border: 1px solid #474755;
    padding: 0 16px;
    background: none;
    height: 40px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #FBFBFF;
}

.bank-selector-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    gap: 8px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #FBFBFF;
}

.bank-input::placeholder {
    color: #767687;
}

.bank-input-prefixIcon {
    position: absolute;
    margin-top: 37px;
    margin-left: 16px;
}

.bank-text {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;

    color: #767687;
}


.bank-input-label {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #FBFBFF;
}

.bank-input-label-error {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    display: flex;
    align-items: center;
    color: #F16063;
}

.modal-text {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    color: #FBFBFF;
    margin-block: 32px;
}

.modal-help-button {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: #FBFBFF;
    cursor: pointer;
    transition: 0.2s color;

}

.modal-help-button:hover {
    color:#7B59FD;

}

.ant-input-affix-wrapper .anticon.ant-input-password-icon {
    color: #767687 !important;
    padding-right: 5px;
}
