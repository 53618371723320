.wallet-custom-tabs .ant-tabs-tab .ant-tabs-tab-btn {
    color: #767687!important;
    font-size: 18px;

}
.ant-tag {
    border: none !important;
    /* !important ломает теги в создании объявления */
    background: transparent;
}

.ant-tag-checkable-checked {
    border: none !important;
    background: transparent !important;
}
.ant-tabs-tab {
    border: none !important;
    background: transparent !important;
}
.ant-tabs-tab-active {
    border: none !important;
    background: transparent !important;
}
.wallet-custom-tabs .ant-tabs-tab-active .ant-tabs-tab-btn {
    color: white !important;
}
.wallet-custom-tabs .ant-tabs-nav{
    padding-bottom: 0 !important;
}
.wallet-custom-tabs .ant-tabs-ink-bar{
    background-color: #F3F3F8;
    height: 1px!important;
}
.wallet-custom-tabs .ant-tabs-nav::before{
    border-bottom: 1px solid #2B2B36!important;
}
