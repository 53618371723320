.bank-container-input {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.bank-container-input-label-error {
    font-family: 'Inter',sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #F16063;
}
