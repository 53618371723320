.order-table-spot > div > div > span,
.order-table-spot > div > div > div > span,
.order-table-spot > div > div > section > div > div > span,
.order-table-spot > div > div > div > div > span {
  font-size: 12px !important;
}
.order-table-spot {
  padding: 16px 16px 16px 16px;
}

.actual-orders-row:hover {
  background-color: rgba(255, 255, 255, 0.05) !important;
}

.history-orders-tags > span:not(.ant-tag-checkable-checked) {
  background-color: transparent !important;
}

.ant-dropdown-menu.ant-dropdown-menu-root.ant-dropdown-menu-vertical.ant-dropdown-menu-light {
  border-radius: 8px;
  padding: 8px 1px;
}

.ant-dropdown-menu.ant-dropdown-menu-root.ant-dropdown-menu-vertical.ant-dropdown-menu-light
  > li {
  transition: none !important;
}



.range-orders-history {
  height: 32px !important;
  width: 260px !important;
  display: flex;
  justify-content: flex-end;
}

.range-orders-history:not(.active-range) > div:nth-child(1),
.range-orders-history:not(.active-range) > div:nth-child(2),
.range-orders-history:not(.active-range) > div:nth-child(3),
.range-orders-history:not(.active-range) > div:nth-child(4) {
  display: none;
}

.absolute-range {
  position: absolute;
  height: 100%;
  padding: 4px 16px;
  top: 0;
}
/*.absolute-range:hover > div > span {*/
/*  color: #fbfbff !important;*/
/*}*/
/*.absolute-range:hover > div > svg > path {*/
/*  fill: #fbfbff !important;*/
/*}*/

/*.range-orders-history:hover > .ant-picker-suffix > span > svg > path {*/
/*  fill: #fbfbff !important;*/
/*}*/

.container-range {
  position: relative;
}

.container-range:hover
  > .range-orders-history
  > .ant-picker-suffix
  > span
  > svg
  > path,
.container-range:hover > .absolute-range > div > svg > path {
  fill: #fbfbff !important;
}
.container-range:hover > .absolute-range > div > span {
  color: #fbfbff !important;
}

.container-range:has(.range-orders-history.active-range) > .absolute-range {
  display: none;
}

.spot-sort-date-history:hover > span,
.spot-sort-date-history:hover > div > svg > path {
  color: #fbfbff !important;
  fill: #fbfbff !important;
}

.order-actual-cancel-all:hover > span {
  color: #f16063 !important;
}

.order-actual-cancel-all:hover > svg > path {
  fill: #f16063 !important;
}

.commission-spot,
.spot-percent,
.spot-market-type > div > span,
.spot-market-type > div > div > span {
  font-size: 12px;
}

.spot-button-buy {
  background: #58bf92 !important;
}
.spot-button-sell {
  background: #f16063 !important;
}

.spot-button-buy:hover {
  background: #56c293 !important;
}
.spot-button-sell:hover {
  background: #f16063 !important;
}

.spot-button-buy:active {
  background-color: #5abf93 !important;
}
.spot-button-sell:active {
  background-color: #f05659 !important;
}

/*.spot-body*/
/*  > .ant-input-affix-wrapper.ant-input-affix-wrapper-borderless*/
/*  > .ant-input.ant-input-borderless {*/
/*  color: transparent !important;*/
/*}*/
