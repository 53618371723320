
.custom_search button {
    display: none;
}

.custom_search > span > span {
    border-radius: 8px !important;
    padding: 8px 16px;
}

.custom_search input .ant-input {
    padding-left: 8px !important;
}

.ant-input-affix-wrapper:hover {
    border-color: #FBFBFF !important;
}
