/*.user-dropdown {
    width: 205px;
}*/

.ant-dropdown-menu-item {
    background: linear-gradient(90deg, #18181F, #2D2D3A);
}

.ant-dropdown-menu-item:hover {
    background: #474755;
}

.ant-dropdown-menu-item-selected {
    border-left: 3px solid transparent;
    background: linear-gradient(0deg, #18181F, #18181F) padding-box, linear-gradient(0deg, #6E27F8 2.1%, #20D9C0 71.42%) border-box;
    color: #FBFBFF !important;
}

.ant-dropdown-menu-item-disabled {
    background: rgba(43, 43, 54, 1) !important;
}

