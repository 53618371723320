.container {
    display: flex;
    align-items: flex-start;
    gap: 20px;

    margin-bottom: 30px;
}

.container > :nth-child(1) {
    width: 20px;
}

.text_container {
    display: flex;
    flex-direction: column;

    gap: 10px;
}

.text_container > * {
    font-size: 12px;
}