.container {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: relative;
    flex-grow: 2;
    padding: 0 0 0 25px;
    height: 0;
    overflow: auto;
    margin: 10px 0 10px 0;
    bottom: 0;
}

/* width */
.container::-webkit-scrollbar {
    width: 8px;
}

/* Track */
.container::-webkit-scrollbar-track {
    background: rgb(65, 65, 83, .3);
    border-radius: .5rem;
}

/* Handle */
.container::-webkit-scrollbar-thumb {
    background: #414153;
    border-radius: .5rem;
}

/* Handle on hover */
.container::-webkit-scrollbar-thumb:hover {
    background: rgb(65, 65, 83, .8);
}

.message__container {
    display: flex;
    scroll-behavior: smooth;
}

.message {
    display: flex;
    flex-direction: column;
    gap: 6px;
    max-width: 307px;
    border-radius: 8px;
    padding: 8px;
}

.message__content {
    color: #fff;
}

.message__info {
    display: flex;
    justify-content: flex-end;
    gap: 4px;
}

.message_alert {
    padding: 16px 8px 8px;
    background: rgba(65, 61, 80);
    border: 1px solid #474755;
    border-radius: 8px;
    width: 340px;
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.message_alert__header {
    color: #fff;
    font-weight: 700;

    display: flex;
    align-items: center;
    justify-content: space-between;
}

.message_alert__content {
    color: #fff;
}

.message__img {
    max-width: 180px;
    max-height: 260px;
    border-radius: 8px;
    overflow: hidden;
    cursor: pointer;
}

.message__img img {
    width: 100%;
    object-fit: cover;
}

.message__pdf {
    display: flex;
    align-items: center;
    gap: 8px;
}

.message__pdf_text {
    color: rgba(251, 251, 255, 1);
}
