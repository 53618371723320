.pair-container {
    display: flex;
    flex-direction: column;
    gap: 9px;
    max-width: 354px;
}

.pair-container-header {
    display: flex;
    align-items: center;
    gap:16px;
    height: 32px;
}

.pair-container-header-button {
    background: none;
    border: none ;
    cursor: pointer;
    padding: 0;
}

.pair-container-header-list-unshow {
    display: flex;
    gap: 16px;
    max-width: 258px;
    overflow-x: hidden;
}

.pair-container-header-button-text {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    color: #767687;
    transition: 0.2s color;
}

.pair-container-header-button-text:hover {
    color: white;
    transition: 0.2s;
}

.pair-container-header-image {
    width: 16px;
    height: 16px;
}

.hover-svg:hover{
    fill: white!important;
    transition: 0.2s;
}

.pair-container-header-fullPair-container {
    margin-top: 40px;
    border-radius: 8px;
    padding: 24px 16px;
    width: 354px;
    background-color: #2B2B36;
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.pair-container-header-search {
    margin-top: 40px;
    margin-left: 32px;
    border-radius: 8px;
    padding: 8px;
    width: 290px;
    min-height: 135px;
    background-color: #2B2B36;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.pair-container-header-search-text {
    font-family: 'Inter',sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #767687;
}

.ant-input-affix-wrapper-focused {
    box-shadow: none!important;
}


