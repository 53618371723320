.custom-button-purple{
    background: linear-gradient(75.86deg, #6320E6 6.84%, #7B59FD 99.53%);
    border-radius: 8px;

}

.custom-button-purple:hover {
    background: linear-gradient(76deg, #7D50FF 6.84%, #7785FF 107.11%)!important;
    border: none;
}

.custom-button-outlined-purple{
    background: linear-gradient(80.47deg, #18181F 6.62%, #2D2D3A 148.62%)!important;
    border-radius: 8px;
    border-color: #7c44dc;
    width: calc(100% - 2px)!important;
    height: 37px!important;
    transition: none;
}
.custom-button-outlined-purple>span{
    color: white!important;
}
.ant-btn-primary.custom-button-outlined-purple:hover{
    background: #2B2B36!important;
}
.ant-btn-primary.custom-button-outlined-purple:focus{
    background: #18181F!important;
}
.ant-btn-primary.custom-button-outlined-purple:active{
    background: #18181F!important;
}
.custom-button-outlined-purple span{
    font-family: 'Inter', sans-serif;
}
.custom-button-purple span, .custom-button-green span{
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: white!important;
}
.custom-button-green{
    background: linear-gradient(75.86deg, #088473 6.84%, #0EC6AD 107.11%)!important;
    border-radius: 8px;
}
.custom-button-container{
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    width: 100%;
    height: 40px;
}

.buttonOutline {
    position: relative;
    border: 1px solid transparent;
    border-radius: 8px;
    background:
        linear-gradient(#1E1E27, #1E1E27) content-box,
        linear-gradient(#1E1E27, #1E1E27) padding-box,
        linear-gradient(75.86deg, #6320E6 6.84%, #7B59FD 99.53%) border-box !important;
}

.buttonOutline:hover {
    background:
        linear-gradient(#2B2B36, #2B2B36) content-box,
        linear-gradient(#2B2B36, #2B2B36) padding-box,
        linear-gradient(52deg, #7D50FF 0%, #7785FF 100%) border-box !important;

}

.buttonOutline:active {
    background:
        linear-gradient(#18181F, #18181F) content-box,
        linear-gradient(#18181F, #18181F) padding-box,
        linear-gradient(52deg, #4603CA 0%, #742EFD 92.44%) border-box !important;
}

.buttonOutline:disabled{
    border-radius: 8px;
    border: 1px solid #2B2B36;
    background: transparent  !important;
}
