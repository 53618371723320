.reverse-exchange-btn {
    border: none !important;
    width: 48px !important;
    height: 48px !important;
    background: transparent;
}

/*.reverse-exchange-btn:hover svg {*/
/*    filter: drop-shadow(1px 5px 20px rgba(82, 151, 255, 0.58));*/
/*}*/

.reverse-exchange-btn:active svg {
    filter: url(#active);
}

.reverse-exchange-btn:disabled svg path{
    fill: #474755;
}
