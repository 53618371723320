.ant-btn-link {
    color: #C3C3CE
}

.ant-btn.ant-btn-link:hover {
    color: #FBFBFF
}

.ant-btn.ant-btn-link:active {
    color: #767687
}