.pair-content-container {
    display: flex;
    flex-direction: column;
    gap:12px
}

.pair-content-name-col {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-inline: 6px;
}

.pair-content-name-container-text {
    background: none;
    border: none;
    display: flex;
    align-items: center;
    gap:6px;
    cursor: pointer;
    padding: 0;
}

.pair-content-name-text {
    font-family: 'Inter',sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #FBFBFF;
}



.pair-content-item {
    display: flex;
    padding-inline: 6px;
    align-items: center;
    height: 32px;
    width: 100%;
    cursor: pointer;
    border: none;
    background: none;
    transition: 0.2s background-color;
}

.pair-content-item-active {
    padding-right: 6px;
    padding-left: 3px;
    display: flex;
    align-items: center;
    height: 32px;
    width: 100%;
    cursor: pointer;
    border: 0 solid transparent;
    border-left: 3px solid transparent;
    /* вариант с градиентной линией справа*/
    /*background: linear-gradient(80.47deg, rgb(20, 20, 31) 6.62%, rgb(20, 20, 31) 148.62%) padding-box, linear-gradient(25.41deg, #6E27F8 0.1%, #20D9C0 15.42%) border-box;*/
    background:  #2B2B36;
    border-color: transparent;
    border-width: 0 0 0 3px;
}

::-webkit-scrollbar {
    width: 4px;
}

/* Track */
::-webkit-scrollbar-track {
    background: none;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #F3F3F8;
    border-radius: 4px;
}

.pair-content-item:hover {
    background-color: #FFFFFF0D;
}

.pair-content-item-text-money {
    font-family: 'Inter',sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: #FBFBFF;
}

.pair-content-item-text {
    font-family: 'Inter',sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    color: #FBFBFF;
}

.pair-content-list-items {
    max-height: 128px;
    overflow-y: auto;
    overflow-x: hidden;
}
