.custom-modal-footer {
    display: flex;
    width: 100%;
    gap: 16px;
}

.custom-modal-footer .ant-btn:nth-child(1) {
    width: 284px;
}

.custom-modal-footer .ant-btn:nth-child(2) {
    width: 180px;
    margin-inline-start: 0 !important;
}

.custom-modal-content {
    display: flex;
    align-items: center;
    gap: 4px;
}


.cancel-order-modal-network-commission span {
    margin-left: 8px;
}

.choose-currency-select-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 8px;
}

.ant-select-item.ant-select-item-option:has(.choose-currency-select-item) {
    min-height: 38px !important;
    border-left: none !important;
}

.ant-select-item.ant-select-item-option:has(.choose-currency-select-item)::after,
.ant-select-item-option-selected:has(.choose-currency-select-item)::after {
    display: none !important;
}

.ant-select-item-option-selected:has(.choose-currency-select-item) {
    border-image: none !important;
    background-color: #2B2B36 !important;
}


.ant-select-item:has(.group-label) {
    cursor: default !important;
    background-color: transparent !important;
}

.ant-select-item.ant-select-item-option-active:has(.choose-currency-select-item) {
    background: #2B2B36 !important;
}

.ant-select-item.ant-select-item-option:has(.choose-currency-select-item):active {
    background: linear-gradient(80.47deg, #18181F 6.62%, #2D2D3A 148.62%) !important;
}

.ant-select.non-border {
    background-color: #2B2B36 !important;
}

.ant-select-item.ant-select-item-option-selected:has(.choose-currency-select-item) {
    background-color: #2B2B36 !important;
}

.ant-select-item-option-content:has(.choose-currency-select-item) svg {
    display: none !important;
}

.ant-dropdown-menu-title-content span.ant-typography.choose-currency-select-item-subtext {
    color: #767687 !important;
}

.modal-wrapper .ant-btn {
    padding: 8px;
    width: 100%;
    text-align: left;
    height: 48px;
    justify-content: space-between;
    border: none;
    box-sizing: border-box;
    transition: all 0s;
}

.modal-wrapper .ant-btn-default:disabled {
    opacity: 0.5;
}

.modal-wrapper .ant-btn-default:not(:disabled) {
    background-color: #2B2B36;
}
.modal-wrapper .ant-btn-default:hover {
    background-color: rgba(255, 255, 255, 0.08);
}

.modal-wrapper .ant-btn-default:not(:disabled):hover {
    /*background-color: #0E0E13;*/
    /*border-left: 3px solid #6E27F8;*/
    color: #FBFBFF;
    border-width: 0 0 0 3px;
    border-style: solid;
    border-color: transparent;
    border-image: initial;
    background: linear-gradient(80.47deg, #0E0E13 6.62%, #0E0E13 148.62%) padding-box padding-box, linear-gradient(5deg, rgb(110, 39, 248) 2.1%, rgb(32, 217, 192) 71.42%) border-box border-box;
    border-radius: 4px;
}

.coin-image-wrapper {
    padding: 4px;
    border-radius: 4px;
    background-color: #474755;
    display: flex;
}

.currency-shadow-text {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: #767687;
}

.ant-dropdown-menu-item-group-list .ant-dropdown-menu-item{
    background: #2B2B36;
}
.ant-dropdown-menu-item-group-list .ant-dropdown-menu-item:hover{
    background-color: #474755 !important;
}
