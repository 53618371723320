.container {
    display: flex;
    gap: 24px;
    flex-direction: column;
}

.title {
    color: #FBFBFF;
    font-size: 28px;
    font-family: Inter, sans-serif;
    font-weight: 700;
}

.container-button {
    display: flex;
    width: 100%;
    align-items: flex-end;
    align-content: flex-start;
    gap: 16px;
    flex: 1 0 0;
    flex-wrap: wrap;
}

