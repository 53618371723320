.footer-container {
    display: flex;
    align-items: flex-start;
    gap: 16px;
    width: 100%;
    height: 40px;
}

.footer-ok-button {
    width: 65%
}

.footer-cancel-button {
    position: relative;
    border: 1px solid #6320E5 !important;
    background-color: transparent;
    color: white;
}

.footer-cancel-button:hover {
    background-color: #2B2B36 !important;
    color: white !important;
}

.footer-cancel-button:active {
    background-color: #18181F !important;
    color: white !important;
}

