*,
*::before,
*::after {
    box-sizing: initial;
}

[class*="ant-"],
[class*="ant-"]::before,
[class*="ant-"]::after {
    box-sizing: border-box;
}

.custom-checkbox {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: baseline;
    font-family: Inter, sans-serif;
}

.custom-checkbox .ant-checkbox .ant-checkbox-inner {
    border: 1px solid #202124;
    border-radius: 0;
}

.custom-checkbox .ant-checkbox-checked .ant-checkbox-inner:after {
    border-color: #202124 !important;
}

.custom-checkbox .ant-checkbox-wrapper-checked:hover .ant-checkbox-inner, .ant-checkbox-checked:hover .ant-checkbox-inner {
    border-color: rgba(40, 40, 40, 0.8) !important;
}

.custom-checkbox .ant-checkbox-checked .ant-checkbox-inner {
    background-color: white;
    border: 1px solid #202124;
}

.custom-checkbox .ant-checkbox-checked::after {
    animation-fill-mode: forwards;
}

/* //TODO до лучших времён
.pixel-checkbox{
    display: flex;
}

.pixel-checkbox > input[type="checkbox"] {
    display: none;
}

.pixel-checkbox > input[type="checkbox"] + *::before{
    display: flex;
    width: 20px;
    height: 20px;
    content: url('../assets/png/pixel_checkbox_true.png');
}

.pixel-checkbox input[type="checkbox"]:checked + *::before {
    cursor: pointer;
    content: url('../assets/png/pixel_checkbox_false.png');
    display: flex;
    justify-content: center;
}
*/
