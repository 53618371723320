.footer {
    width: 100%;

    display: flex;
    gap: 10px;
}

.footer > :nth-child(1) {
    flex-grow: 1.5;
}

.footer > :nth-child(2) {
    flex-grow: 1;
}