
.create-ad-cards-wrapper {
    display: flex;
    gap: 24px;
}

.dark-radio-button-group label::before {
    display: none !important;
}


#choose-limits-form .ant-form-item-extra {
    color: white !important;
}

#create-ad-additional-params .ant-form-item-extra {
    color: white !important;
}

#choose-limits-form .ant-form-item-control-input:has(.ant-input-status-error) ~ .ant-form-item-extra {
    display: none;
}


.choose-p2p-ad-type-card {
    background-color: #1C1C27;
    width: 50%;
    height: 556px;
    border-radius: 16px;
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 40px;
    justify-content: space-between;
}

.set-p2p-ad-additional-params-card {
    background-color: #1C1C27;
    width: 100%;
    height: 556px;
    border-radius: 16px;
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 40px;
    overflow-y: scroll;
    max-height: 100%;
}

.set-p2p-ad-additional-params-wrapper-cards {
    display: flex;
    gap: 24px;
}

.set-p2p-ad-additional-params-left-card, .set-p2p-ad-additional-params-right-card {
    background-color: #1C1C27;
    width: 50%;
    height: 464px;
    border-radius: 16px;
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 40px;
    /*overflow-y: auto;*/
    max-height: 100%;

    &::-webkit-scrollbar {
        width: 4px;
    }

    &::-webkit-scrollbar-track {
        background: none;
    }

    &::-webkit-scrollbar-thumb {
        background: #F3F3F8;
        border-radius: 4px;
    }
}

.grey-digits {
    color: #767687;
}

.check-ad-data-card {
    background-color: #1C1C27;
    width: 100%;
    height: 556px;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    max-height: 100%;
}

.choose-limits-and-payment-type-card {
    background-color: #1C1C27;
    width: 50%;
    height: 556px;
    border-radius: 16px;
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 25px;
    justify-content: space-between;
}

.choose-limits-and-payment-type-card .p2p-ad-card-content .card-content-wrapper {
    display: flex;
    flex-direction: column;
    gap: 42px;
}


.limits-form-content-wrapper {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.choose-p2p-ad-limits-and-payment-type {
    background-color: #1C1C27;
    width: 50%;
    height: 556px;
    border-radius: 16px;
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: space-between;
}

.create-p2p-ad-card-title {
    font-size: 24px;
    font-weight: 700;
}

.create-p2p-ad-medium-text {
    font-size: 16px;
    font-weight: 700;
}

.small-bold-text {
    font-size: 14px;
    font-weight: 700;
}


.p2p-ad-card-content {
    display: flex;
    gap: 32px;
    flex-direction: column;
}

.choose-p2p-ad-type-radio-group-dark {
    margin-bottom: 8px;
    display: flex;
    border-radius: 8px;
    background-color: rgb(43, 43, 54) !important;
}

.choose-p2p-ad-type-radio-group-dark .ant-radio-button-wrapper.ant-radio-button-wrapper-checked {
    border-radius: 8px !important;
}

.choose-p2p-ad-type-radio-group-dark > label:nth-child(2)::before {
    display: none;
}

.choose-p2p-ad-type-radio-group-dark-button-text {
    font-weight: 700;
    font-size: 14px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.choose-p2p-ad-type-dropdown-list {
    display: flex;
    gap: 4px;
    align-items: center;
}

.choose-p2p-ad-type-dropdown-list-item {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;
}

.choose-p2p-ad-type-dropdown-list-item-icon {
    display: flex;
    align-items: center;
    padding-bottom: 16px;
}

.choose-p2p-ad-type-bottom-block {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 68px;
    background-color: #413D5080;
    border-radius: 8px;
}

.choose-p2p-ad-type-bottom-block .centered-block {
    display: flex;
    gap: 12px;
    align-items: center;
}

.choose-p2p-ad-type-bottom-block .row-item,
.check-ad-data-card-content .money-info .item .centered-block,
.choose-p2p-ad-type-price-type-wrapper .centered-block {
    display: flex;
    gap: 8px;
    align-items: center;
}

.choose-p2p-ad-type-tag-list-wrapper {
    display: flex;
    gap: 8px;
}


.price-slider-slider-wrapper,
.choose-payment-region,
.limits-form-content-wrapper-content,
.check-ad-data-card-content {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.p2p-price-type {
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
}

.choose-payment-type-wrapper {
    display: flex;
    flex-direction: column;
}

.choose-payment-type-header {
    display: flex;
    justify-content: space-between;
}


.limits-form-content-wrapper-content .listing,
.choose-payment-type-wrapper .text-container,
.additional-info-wrapper > div {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
}

.payments-type-list-wrapper {
    display: flex;
    gap: 8px;
    flex-direction: column;
    max-height: 200px;
    overflow-y: scroll;
}

.payments-buy-type-list-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    margin-top: 12px;
}

.payment-buy-card, .payment-buy-card-third, .payment-buy-card-fourth {
    cursor: pointer;
    padding: 8px;
    min-width: 250px;
    height: 48px;
    background-color: #2B2B36;
    border-radius: 8px;
}

.payment-buy-card-fourth {
    padding: 8px 16px;
    min-width: 356px;
    width: calc(33.333% - 8px)
}

.payment-buy-card:hover, .payment-buy-card-third:hover {
    background: rgba(71, 71, 85, 1);
}

.payment-buy-card-third {
    min-width: 356px;
}

.payment-buy-card-data-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.payment-buy-card-info {
    display: flex;
    align-items: center;
    gap: 8px;
}

.payment-buy-card-text, .payment-buy-card-text-third, .payment-buy-card-text-fourth {
    display: flex;
    flex-direction: column;
    max-width: 180px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.payment-buy-card-text-third, .payment-buy-card-text-fourth {
    max-width: 230px;
}

.payment-type-list-item {
    position: relative;
    padding: 8px 16px;
    align-items: center;
    gap: 8px;
    flex-grow: 1;
    background-color: #2B2B36;
    border-radius: 4px;
}

.payment-buy-card-icon-wrapper {
    display: flex;
    justify-content: center;
}

.dotted-line {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.payment-buy-card-title {
    line-height: 16.94px;
    font-size: 14px;
}

.payment-type-list-item .data-wrapper {
    display: flex;
    gap: 8px;
    flex-grow: 1;
    justify-content: space-between;
}

.payment-type-list-item .data-wrapper .data-content {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.payment-type-list-item .data-wrapper .data-content .title,
.payment-type-list-item .data-wrapper .data-content .payment-type-value {
    font-size: 14px;
}

.payment-type-list-item .data-wrapper .data-content .subtitle {
    font-size: 14px;
    color: #767687;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.payment-type-list-item .data-wrapper .icon-container,
.data-content-wrapper .icon-container {
    display: flex;
    justify-content: center;
    padding: 4px;
    gap: 8px;
}

.limits-form-content-wrapper-content .listing .list-item,
.check-ad-data-card-content .volume-info {
    display: flex;
    justify-content: space-between;
}

.coin-limits {
    display: flex;
    gap: 4px;
    width: 100%;
}

.coin-limits .arrow-icon-wrapper {
    margin-top: 44px;
}

.wallet-data-wrapper {
    display: flex;
    gap: 12px;
    align-items: center;
    width: fit-content;
}

.choose-p2p-ad-type-slider-form-wrapper {
    display: flex;
    gap: 16px;
}

.choose-p2p-ad-type-slider-form-wrapper .ant-slider {
    width: 70%;
}

.choose-p2p-ad-type-price-type-wrapper {
    display: flex;
    gap: 59px;
}


.choose-p2p-ad-type-helper-text {
    font-size: 12px;
    color: #767687 !important;
}

.choose-p2p-ad-type-price-bottom-block {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    width: 100%;
    height: 68px;
    background-color: #413D5080;
    border-radius: 8px;
}

.time-icon-wrapper {
    display: flex;
    align-items: center;
    gap: 8px;
}

.create-ad-cards-wrapper .limits-bottom-block {
    display: flex;
    padding: 13px 24px;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 68px;
    background-color: #413D5080;
    border-radius: 8px;
}


.create-ad-cards-wrapper .additional-param-form-item {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.create-ad-cards-wrapper .additional-param-form-item .item {
    display: flex;
    justify-content: space-between;
}

.check-ad-data-card-content .money-info {
    display: flex;
    padding: 0 0 12px;
    justify-content: space-between;
}

.check-ad-data-card-content .money-info .item {
    width: 33.33%;
    display: flex;
}

.check-ad-data-card-content .money-info .item:nth-child(2) {
    display: block;
}

.check-ad-data-card-content .money-info .item:nth-child(3) {
    gap: 60px;
}

.check-ad-data-card-content .helper-text {
    font-size: 14px;
    color: #767687;
}

.check-ad-data-card-content .region {
    display: flex;
    padding-top: 16px;
}

.check-ad-data-card-content .volume-info > div,
.check-ad-data-card-content .region > div {
    width: 33.33%;
}

.check-ad-data-card-content .payment-types-list-wrapper {
    display: flex;
    flex-wrap: wrap;
    margin-top: 16px;
    gap: 8px;
}

.check-ad-data-card-content .payment-types-list-wrapper .payment-card {
    display: flex;
    padding: 8px 16px;
    gap: 8px;
    background-color: #2B2B36;
    border-radius: 4px;
    flex-basis: calc(33% - 8px);
}

.check-ad-data-card-content .payment-types-list-wrapper .payment-card .data-content-wrapper {
    display: flex;
    gap: 8px;
    flex-grow: 1;
    justify-content: space-between;
}

.check-ad-data-card-content .payment-types-list-wrapper .payment-card .data-content-wrapper .data-content {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.check-ad-data-card-content .payment-types-list-wrapper .payment-card .data-content-wrapper .data-content .subtitle {
    font-size: 14px;
    color: #767687;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.check-ad-data-card-content .additional-info-wrapper {
    display: flex;
    flex-direction: row;
    gap: 24px;
    max-width: 100%;
    padding-top: 16px;
}

.choose-p2p-ad-type-price-type-wrapper .item {
    display: flex;
    gap: 12px;
    flex-direction: column;
}

.create-ad-tag,
.delete-payment-type-icon,
.edit-payment-type-icon {
    cursor: pointer;
}

.delete-payment-type-icon:hover,
.edit-payment-type-icon:hover {
    color: white !important;
}
