.exchange-timer-card {
    width: calc(280px - 48px - 4px);
}

.exchange-order-data-card {
    width: calc(652px - 48px - 4px);
}

.exchange-order-transfer-card {
    width: calc(380px - 48px - 4px);
}


.exchange-card-header {
    font-size: 24px;
    line-height: 29px;
    font-weight: 700;
    display: flex;
}

.exchange-card-timer {
    line-height: 38.73px;
    display: flex;
    margin: 56px 0 48px 0;
    color: #FBFBFF;
    font-size: 32px;
    font-weight: 700;
    line-height: 120%;
}

.exchange-card-timer.timer-disabled {
    color: #767687;
}

.exchange-card-divider {
    margin: 0;
    border-color: #474755;
}

.exchange-card-order-create-time-wrapper {
    display: flex;
    margin-top: 8px;
}

.exchange-card-subheader {
    width: 100%;
    font-weight: 700;
    font-size: 14px;
    line-height: 16.94px;
    display: flex;
    margin-top: 40px;
}

.exchange-card-order-number {
    line-height: 19.36px;
    display: flex;
    margin-top: 40px;
}

.exchange-card-order-number-description {
    line-height: 16.94px;
    display: flex;
    margin-top: 4px;
    font-weight: 400;
}

.exchange-data-card-header-space {
    margin-top: 49px;
}

.exchange-data-card-currency-container {
    height: 49px;
}

.exchange-data-card-list-wrapper {
    margin-top: 44px;
}

.exchange-data-card-course {
    line-height: 19px;
    margin: 34px 0 55px 0;
}


.exchange-card-header.exchange-transfer-card-title {
    display: block;
    padding: 0 40px;
}

.exchange-transfer-card-subheader {
    font-size: 14px;
    font-weight: 400;
    line-height: 16.94px;
    display: block;
    text-align: center;
    margin-top: 44px;
    padding: 0 50px;
}

.exchange-transfer-card-qrcode-wrapper {
    display: flex;
    padding: 24px;
}

.exchange-transfer-card-wallet-address-btn-wrapper {
    display: flex;
}

.exchange-card-wallet-balance {
    line-height: 19.36px;
    display: block;
    margin-top: 36px;
}

.exchange-card-loader-wrapper {
    display: flex;
    height: 100%;
    padding: 24px;
}

.exchange-card-loader-wrapper .exchange-card-loader {
    font-size: 50px;
    color: #FBFBFF;
}
