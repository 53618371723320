.exchange-container {
    margin-top: 46px;
    display: flex;
    flex-direction: column;
    gap: 72px;
}

.exchange-left-card {
    /*    background-image: url('../../ui/assets/svg/changeCurrencyBackgroundColored-cropped.svg');
        background-repeat: no-repeat;*/
    background: radial-gradient(106.63% 327.54% at 4.27% 100%, #E272B6 0%, #CE80EF 51.04%, #7EABFF 92.44%);
    box-shadow: 0 0 1px rgba(12, 26, 75, 0.2), 0 30px 40px rgba(20, 37, 63, 0.1);
    position: relative;
    width: 389px;
    height: 384px;
    border-radius: 24px;

    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 80%, 80% 100%, 0 100%);
    clip-path: polygon(0 0, 100% 0, 100% 80%, 80% 100%, 0 100%);
}

.exchange-card-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 40px;
}

.exchange-content-container {
    padding-top: 72px;
    display: flex;
    flex-direction: row;
    gap: 80px;
    align-items: flex-start;
}

.exchange-form-container {
    max-width: 316px;
    width: 100%;
}

.exchange-tooltip-container {
    display: inline-flex;
    flex-direction: column;
    gap: 8px;
}

.exchange-tooltip-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    gap: 10px;
    width: 331px;
}

.exchange-select > div {
    color: white !important;
    font-family: Inter, sans-serif !important;
    font-size: 18px !important;
    line-height: 120% !important;
    font-weight: 700 !important;
}

.exchange-card-currency-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
}

.exchange-card-info-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.exchange-card-info {
    background: rgba(32, 33, 36, 0.2);
    border-radius: 6px;
    width: fit-content;
    padding: 4px 16px;
}

.exchange-card-info-black {
    background: #6B6E75;
    border-radius: 6px;
    width: fit-content;
    padding: 4px 16px;
}
