.ant-collapse-header {
    padding-left: 16px !important;
    padding-right: 16px !important;
}

.ant-collapse-item.ant-collapse-no-arrow {
    border-radius: 8px !important;
}

.ant-collapse-item.ant-collapse-item-active {
    border-radius: 8px !important;
}

.collapseContainer > div > p,
.collapseContainer > div > div > p,
.collapseContainer > div > div > div > p {
    font-weight: 500;
    font-size: 14px;
    font-family: Inter, sans-serif;
    margin-bottom: 8px;
}

.collapseContainer > div > p:nth-child(1),
.collapseContainer > div > div > p:nth-child(1),
.collapseContainer > div > div > div > p:nth-child(1) {
    color: #fafafc;
}

.collapseContainer > div > p:nth-child(2),
.collapseContainer > div > div > p:nth-child(2),
.collapseContainer > div > div > div > p:nth-child(2) {
    color: #c3c3ce;
}

.limit > div {
    display: flex;
    justify-content: space-between;
}

.collapseInput {
    display: flex;
    justify-content: space-between;
    align-items: start;
    height: 100%;
    margin-bottom: 32px;
}

.collapseInput > div:nth-child(1) {
    margin-right: 16px;
}

.collapseInput > div:nth-child(2) {
    margin-right: 32px;
}

.ant-form-item-control-input-content {
    font-weight: 500;
    font-size: 14px;
    font-family: Inter, sans-serif;
    color: #fafafc;
}

.ant-form-item-control-input-content > span {
    margin-top: 8px;
}

.terms {
    font-weight: 500;
    font-size: 14px;
    font-family: Inter, sans-serif;
    margin-top: 48px;
}

.terms > div {
    display: flex;
}

.terms > div > div {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.terms > div > div > span {
    color: #fbfbff;
    margin-right: 16px;
}

.terms > div > div > div {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.terms > p:last-child {
    margin-bottom: 10px;
    color: #c3c3ce;
}

.ant-collapse-header:hover[aria-expanded='false'] {
    background-color: #2b2b36;
    border-radius: 8px !important;
}

.ant-collapse-header:hover[aria-expanded='true'] {
    border-radius: 8px 8px 0 0 !important;
}

.ant-collapse-header:active {
    background-color: #181827;
}

.currencyCrypto {
    color: #fbfbff;
    font-size: 12px;
}

.ant-input-affix-wrapper.css-dev-only-do-not-override-1voliev {
    margin: 0 !important;
}

.ant-input[type='number']::-webkit-outer-spin-button,
.ant-input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.ant-tag.ant-tag-has-color {
    word-break: break-all !important;
}

.helpInput {
    font-size: 12px;
    font-weight: 400;
    color: #f16063;
}

.custom-search button {
    display: none;
}

.custom-search > span > span {
    border-radius: 8px !important;
    padding: 16px 20px;
}

.custom-search input .ant-input {
    padding-left: 8px !important;
}

/*.ant-form-item-explain-error {*/
/*    margin-top: 4px;*/
/*    font-size: 12px;*/
/*}*/
.custom-collapse .ant-collapse-item {
    border: none;
    border-radius: 0;
    margin-bottom: 16px;
}

.custom-collapse .ant-collapse-header {
    color: #333;
    font-weight: bold;
    padding: 16px;
}

.custom-collapse .ant-collapse-content {
    padding: 16px;
}
