.blur-circle {
    background: radial-gradient(102.69% 213.84% at 1.37% 61.2%, #BC40E8 0%, #FFA0A6 43.92%, #FDFF95 92.44%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
    opacity: 0.4;
    filter: blur(18px);
    border-radius: 50%;
    height: 184px;
    width: 184px;
    z-index: 0;
    position: relative;
}

.icon > svg {
    width: 100%;
    height: 100%;
}

.white-icon > svg > path {
    fill: white;
}
