.content__container {
    display: flex;
    flex-direction: column;
    gap: 32px;
}

.text {
    /*color: #FBFBFF;*/
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 0;
}

.content__file {
    padding: 8px;
    height: 280px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(65, 61, 80, 0.50);
    border-radius: 16px;
}

.content__filePdf {
    position: relative;
    height: fit-content;
    padding: 19px 16px;
    justify-content: space-between;
}

.filePreview {
    height: 100%;
    display: flex;
    flex-direction: column;
    max-width: 400px;
    gap: 10px;
    align-items: center;
}

.filePreview img {
    /*border-radius: 8px;*/
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.fileNamesList div {
    max-width: 100%;
}

.deleteBtn {
    position: absolute;
    right: 8px;
    top: 8px;
}

.deleteBtnPdf {
    position: static;
    right: auto;
    top: auto;
}

.footer {
    display: flex;
    gap: 16px;
}
