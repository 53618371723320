.button-card {
    background-color: #2B2B36;
    border-radius: 4px;
    border: none;
    border-left: 3px solid rgba(0, 0, 0, 0);
    padding: 8px 16px;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    transition: 0.2s background-color;
}

.button-card:hover {
    background-color: #474755;
}

.button-card:active {
    background-color: #18181F;
}

.button-card-selected {
    border-left: 3px solid transparent;
    background: linear-gradient(#0E0E13, #0E0E13) padding-box, linear-gradient(25.41deg, #6E27F8 2.1%, #20D9C0 71.42%) border-box;
}

.ant-select-item-option-content {
    display: flex;
    align-items: center;
}

.ant-select-dropdown {
    padding-left: 0 !important;
    padding-right: 0 !important;
}
