.flex {
    display: flex;
}

.flex-wrap-wrap {
    flex-wrap: wrap;
}

.left-3 {
    margin-left: 3px;
}

.width-50 {
    width: 50%;
}

.size-85-22 {
    width: 85px;
    height: 22px;
}

.flex-column {
    display: flex;
    flex-direction: column;
}

.flex-row {
    display: flex;
    flex-direction: row;
}

.flex-between {
    justify-content: space-between;
}

.flex-start {
    justify-content: flex-start;
}

.content-end {
    justify-content: flex-end;
}

.items-end {
    align-items: flex-end;
}

.gap-4 {
    gap: 4px;
}

.gap-6 {
    gap: 6px;
}

.gap-8 {
    gap: 8px;
}

.gap-14 {
    gap: 14px;
}

.items-center {
    align-items: center;
}

.text-bold {
    font-size: 16px;
    line-height: 19px;
    color: #FBFBFF;
    font-weight: 700;
}

.text-big {
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: #FBFBFF;
}

.text-regular {
    font-size: 14px;
    line-height: 17px;
    color: #C3C3CE;
}

.text-small {
    font-size: 12px;
    line-height: 15px;
    color: #C3C3CE;
}

.text-white {
    color: #FBFBFF;
}

.text-gray {
    color: #767687;
}

.ann-button {
    width: 32px!important;
    height: 32px!important;
    border-radius: 6px !important;
    display: flex;
    justify-content: center !important;
    align-items: center !important;
    background: transparent;
    transition: all .2s ease;
    cursor: pointer;
    border: none !important;
    position: relative;
    padding: 0 !important;
    & button {
        padding: 0!important;
        width: 32px!important;
        height: 32px!important;
    }
}

.ann-button svg {
    fill: #C3C3CE;
}

.ann-button:hover {
    background: #474755 !important;
}

.ad-bottom-link {
    padding: 8px 16px;
    border-radius: 8px;
    transition: all .2s ease;
}

.ad-bottom-link:hover {
    background: #474755;
}

.ant-radio-wrapper .ant-radio-inner {
    border: 1px solid #FBFBFF !important;
    background: transparent !important;
}

.ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
    border: 1px solid #6320E5 !important;
}

.ant-radio-wrapper .ant-radio-checked .ant-radio-inner:after {
    background-color: #6320E5 !important;
}

.ant-tag {
    margin-right: 4px;
    padding: 4px;
    background: #474755;
    border-radius: 4px;
}

.left-auto {
    margin-left: auto;
}

.skeleton-paragraph {
    height: 100px;
}

.skeleton-paragraph-thin,
h3.skeleton-paragraph {
    height: 20px;
}

.skeleton-paragraph-medium {
    height: 50px;
}

.skeleton-paragraph li {
    height: 100% !important;
}

.customTitle {
    display: none;
    position: absolute;
    min-height: 30px;
    background: #E2E2E8;
    border-radius: 4px;
    padding: 6px;
    max-width: 285px;
}

.customTitle.upper {
    /*top: -130%;*/
    right: -10px;
}

.customTitle svg {
    position: absolute;
    right: 19px;
}

.customTitle.lower svg {
    top: -5px;
    transform: rotate(180deg);
}

.customTitle.upper svg {
    bottom: -5px;
}

.customTitle.lower {
    /*bottom: -130%;*/
    right: -10px;
}

.status.customTitle {
    min-width: 200px;
    top: -40px;
    left: -17px;
    right: unset;
}

.status.customTitle svg {
    left: 15px;
}

.template.status.customTitle {
    min-width: 80px;
    top: -40px;
    left: -17px;
    right: unset;
}

.ann-button:hover .customTitle,
.statusCircle:hover .customTitle {
    display: flex;
}

.ann-button.disabled:hover {
    background: transparent !important;
    cursor: default;
}

.ann-button.disabled svg {
    fill: #474755 !important;
}

button.ann-button > span {
    display: none;
}

.icon-container {
    width: 20px;
    height: 21px;
}

.icon-container.small {
    width: 15px;
    height: 15px;
}

.icon-container.small-16 {
    width: 16px;
    height: 16px;
}

.icon-container svg {
    max-width: 100%;
    height: 100%;
}

.arrow-container {
    height: 100%;
    justify-content: flex-end;
    padding-bottom: 5px;
}

.arrow-container svg {
    width: 16px;
    height: 16px;
}

.hr {
    width: 100%;
    display: flex;
    border-radius: 4px;
    overflow: hidden;
    height: 6px;
}

.hr .green {
    height: 100%;
    width: 50%;
    background: linear-gradient(80.91deg, #088473 0%, #12BFA8 100%);
}

.hr .gray {
    height: 100%;
    width: 50%;
    background: #474755;
}

.ellipsis-240 {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 240px;
}

.payment-ico-container {
    height: 100%;
    width: 25px;
    padding-top: 4px;
}

.payment-card {
    width: 355px;
    overflow: hidden;
    background: #2B2B36;
    border-radius: 4px;
    gap: 8px;
    padding: 8px 16px;
    display: flex;
    position: relative;
}

.delete-payment-way {
    top: 15px;
    right: 24px;
    position: absolute;
    cursor: pointer;
    width: 16px !important;
    height: 16px !important;
    border: none !important;
    background: transparent !important;
    padding: 0 !important;
    margin: 0 !important;
}

.delete-payment-way svg {
    fill: #767687;
    transition: all .2s ease;
}

.delete-payment-way:hover svg {
    fill: #FBFBFF;
}

.ad-content {
    height: 475px;
    overflow-x: hidden;
    overflow-y: scroll;
    padding: 0 24px 24px 24px;
}

.ad-content::-webkit-scrollbar {
    width: 4px;
}

/* Track */
.ad-content::-webkit-scrollbar-track {
    background: none;
}

/* Handle */
.ad-content::-webkit-scrollbar-thumb {
    background: #F3F3F8;
    border-radius: 4px;
}

.cursor-pointer {
    cursor: pointer;
}

.z-index-fixer * {
    z-index: 2;
}

.z-index-fixer {
    position: relative;
}

.link-overlay {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1 !important;
    transition: all .2s ease;
    border-radius: 8px;
}

.z-index-fixer:hover .link-overlay {
    background: #2B2B36;
}

.link-visited {
    background: #181827;
}

.announcement-card {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 24px;
    gap: 20px;
    width: 100%;
    height: 100px;
    background: #1C1C27;
    border: 1px solid #474755;
    border-radius: 8px;
}

.type-sell, .type-buy {
    border-radius: 6px;
    width: 20px;
    position: absolute;
    height: 100%;
    overflow: hidden;
    left: 0;
    top: 0;
}

.type-buy {
    border-left: 2px solid #6320E5;
}

.type-sell {
    border-left: 2px solid #088473;
}

.ant-picker-content .ant-picker-cell-range-hover::before {
    border: none !important;
    background: rgba(25%, 24%, 31%, 0.5);
}

.ant-picker-content .ant-picker-cell-range-hover::after {
    border: none !important;
}

.ant-picker-content .ant-picker-cell-range-hover-end::before {
    border: none !important;
    background: rgba(25%, 24%, 31%, 0.5);
    border-radius: 0 4px 4px 0;
}

.ant-picker-content .ant-picker-cell-range-hover-end::after {
    border: none !important;
}

.ant-picker-content .ant-picker-cell-range-hover-start::before {
    border: none !important;
    background: rgba(25%, 24%, 31%, 0.5);
    border-radius: 4px 0 0 4px;
}

.ant-picker-content .ant-picker-cell-range-hover-start::after {
    border: none !important;
}

