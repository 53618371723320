.card {
    display: flex;
    width: 100%;
    padding: 16px;
    justify-content: space-between;
    align-content: flex-start;
    row-gap: 16px;
    flex-wrap: wrap;
    border-radius: 8px;
    border: 1px solid rgba(65, 61, 80, 0.50);
    background: linear-gradient(55deg, #18181F 0%, #2D2D3A 142.44%);
}

.card-title-mobile {
    color: #FBFBFF;
    font-size: 16px;
    font-family: Inter, sans-serif;
    font-weight: 500;
}

.card-header {
    display: flex;
    height: 48px;
    align-items: center;
    gap: 8px;
}

.card-header-text-container {
    display: flex;
    height: 40px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 4px;
}

.card-header-text-coin {
    overflow: hidden;
    color: #767687;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 14px;
    font-family: Inter, sans-serif;
}

.card-header-icon-container {
    border-radius: 40px;
    opacity: 0.4000000059604645;
    background: linear-gradient(360deg, #6E27F8 0%, #20D9C0 100%);
    filter: blur(18.5px);
    position: absolute;
    width: 50px;
    height: 50px;
    margin-left: -5px;
}

/*.ant-input-number-input-wrap {*/
/*    width: max(203px, 80%);*/
/*}*/

.card-content {
    display: flex;
    width: 100%;
    align-items: flex-start;
    gap: 8px;
    flex-shrink: 0;
}

.card-content-button {
    display: flex;
    width: max(99px, 20%);
    height: 48px;
    padding: 4px 8px;
    border: none;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
    border-radius: 6px;
    background-color: #2B2B36;
}

.card-content-button-text {
    color: #FBFBFF;
    font-size: 18px;
    font-family: Inter, sans-serif;
    font-weight: 700;
}
