/* Exchange Cards */
.card-title {
    font-size: 32px !important;
}

.choose-currency-card-btn {
    width: fit-content !important;
    display: flex !important;
    align-items: center !important;
    gap: 24px;
    cursor: pointer;
    background-color: inherit;
    border: none !important;
    padding: 0 !important;
    justify-content: flex-start !important;
}

.choose-currency-card-btn:hover {
    background-color: inherit !important;
}

.currency-icon {
    width: 40px;
    height: 40px;
}

.select-caret-icon {
    color: #FBFBFF !important;
    font-size: 16px;
}

.choose-currency-card-tags-container {
    display: flex;
    gap: 10px;
    flex-direction: column;
}

.choose-currency-card-tags-container .choose-currency-card-tag {
    width: fit-content;
    background-color: #474755;
    border: none;
}


.choose-currency-card-tag span{
    color: #FBFBFF;
    font-size: 16px;
}


/* Exchange Form */
.exchange-form{
    display: flex;
    flex-direction: column;
    gap: 32px;
}

.exchange-form .ant-form-item{
    margin-bottom: 0 !important;
}

.exchange-form .ant-form-item-label{
    color: #FBFBFF !important;
}

.exchange-form .form-title{
    font-size: 24px;
    font-weight: 700;
    line-height: 120%;
    color: #FBFBFF;
}

.exchange-form-description-wrapper{
    display: flex;
    flex-direction: column;
    gap: 16px;
}
.exchange-form-description{
    display: flex;
    align-items: center;
    gap: 8px;
}
.exchange-form-description-item{
    display: flex;
}
.bold{
    font-weight: 700;
}

.tooltip-icon{
    cursor: help;
    fill: #474755;
}