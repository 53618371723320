.container-card {
    display: inline-flex;
    width: 100%;
    padding-bottom: 16px;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    flex-shrink: 0;
}

.container-card-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
}

.container-card-text-footer {
    display: flex;
    align-items: flex-start;
    gap: 8px;
}

.container-card-text-footer-small {
    color: #FBFBFF;
    font-size: 14px;
    font-family: Inter, sans-serif;
}

.container-card-text-footer-large {
    color: #FBFBFF;
    font-size: 14px;
    font-weight: 700;
    font-family: Inter, sans-serif;
}
